<template>
    <div>
        <modal :name="modal_name" class="select-addons-modal final-modal popup-modal-all-width-height" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">EDIT ADD-ON</span>
                    <span @click="hideSelectAddons()">
                        <i class="icon icon-close pointer"></i>
                    </span>
                </div>
                <div class="general-section" style="padding: 15px 0px;">
                <div class="v-modal-body">                 
                    <div class="">
                        <div class="infoHeader">
                            <div class="descriptionheader">
                                <div class="description-text-header-left">
                                    <h5>
                                        <span class="font-weight-bold text-secondary fs-16 whiteSpace">Add-On</span>
                                    </h5>
                                </div>
                                <div class="description-text-header-right">
                                    <span @click="expandAddons()" class="collapse-addon">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/addon-collapse.svg" :class="{'iconRotate':showAddons == true}" class="w-30 rotateIcon">
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div v-show="showAddons == true" v-for="(section,section_index) in addons" :key="section_index">
                            <div class="infoHeader">
                                <div class="descriptionheader">
                                    <div class="description-text-header-left">
                                        <h5>
                                            <span class="font-weight-bold text-secondary fs-16">{{section.addon_section_name}}</span>
                                        </h5>
                                    </div>
                                    <div class="description-text-header-right-small">
                                        <span class="collapse-addon" @click="collapseAddons(section)">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/main-collapse.svg" v-if="showSectionAddons.includes(section.addon_section_name)" style="width:25px;">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/arrow-down.svg"  style="width:25px;" v-else>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap" v-if="showSectionAddons.includes(section.addon_section_name)">
                                <div class="addon-main mt-4 ml-3" v-for="(addon,addon_index) in section.addons" :key="addon_index">
                                    <div class="variant-addon" :class="{'selected_variant':selected_addon_ids.includes(addon._id)}">
                                        <div @click="selectAddon(addon,addon_index)">
                                            <avatar
                                                class="product-avatar content-center pt-7"
                                                v-if="addon.image_url == ''"
                                                :username="addon.name.replace(/ .*/,'')"
                                                :src="addon.image_url"
                                                :size="50"
                                                :rounded="false"
                                                color="#f5a623"
                                                background-color="transparent">
                                            </avatar>
                                            <img :src="addon.image_url" class="avatar-addons" :alt="addon.name" v-else>
                                            <p class="unavailable-addons" v-if="addon.quantity == 0">Unavailable</p>
                                        </div>
                                        <div v-if="selected_addon_ids.includes(addon._id)" class="input-prepend-append bg-orange addon-quantity d-flex pl-1 pr-1">
                                            <a class="btn-prepend pt-1 pointer" @click="decreaseQuantity(addon,addon_index)">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minus-addon.svg" style="width:25px;transform: rotate(90deg);">
                                            </a>
                                            <input @keypress="checkOty(addon,addon_index)" @change="addonQty(addon,addon_index)" :name="'item_qty'+addon_index.toString()"  v-model.number="addon.selected_quantity" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" type="number" max="1000" class="form-control bg-orange w-52 text-center">
                                            <a class="btn-append pt-1 pointer" @click="increaseQuantity(addon,addon_index)"> 
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/plus-addon.svg" style="width:25px">
                                            </a>
                                        </div>
                                    </div>
                                    <span class="d-flex justify-content-start mt-4">
                                        <div>
                                            <el-tooltip  v-if="addon.type_id.name == 'Non-Veg'" content="Non-Veg" placement="top">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" style="width:11px;">
                                            </el-tooltip>
                                            <el-tooltip  v-else-if="addon.type_id.name == 'Veg'" content="Veg" placement="top">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" style="width:11px;">
                                            </el-tooltip>
                                            <el-tooltip  v-else-if="addon.type_id.name == 'Egg'" content="Egg" placement="top">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" style="width:11px;"> 
                                            </el-tooltip>    
                                            <el-tooltip  v-else-if="addon.type_id.name == 'Pescatarian'" content="Pescatarian" placement="top">
                                                <img style="width:11px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg">
                                            </el-tooltip>
                                        </div>    
                                        <div class="col addon-col">
                                            <span class="word-break text-left mt-3">{{addon.name}}</span>
                                            <span class="d-block text-left mt-1">{{addon.formatted_price}}</span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <span class="collapse-addon mt-2" @click="expandNotes()" :class="{'mt-4':showAddons == true}">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/addon-collapse.svg" :class="{'iconRotate':showNotes == true}" class="w-30 rotateIcon">
                        </span>
                        <span class="d-flex text-secondary font-weight-bold fs-16 mt-2" :class="{'mt-4':showAddons == true}">Notes</span>
                        <hr class="ml-15 addon-border mr-8">
                        <textarea v-if="showNotes == true" class="form-control addon-note mt-2" v-model="note"></textarea>
                        <span class="collapse-addon mt-4" @click="expandPreferences()">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/addon-collapse.svg" :class="{'iconRotate':showPreferences == true}" class="w-30 rotateIcon">
                        </span>
                        <span class="mt-4 d-flex text-secondary font-weight-bold fs-16">Preferences</span>
                        <hr class="ml-26 addon-border mr-8">
                        <span v-show="preferences.length>0 && showPreferences == true" class="preferences mt-2" v-for="(name,pref_index) in preferences" :key="pref_index">
                            {{name}}
                        </span>
                        <span v-if="showPreferences == true" v-show="preferences.length==0" class="d-block font-weight-bold mt-2 text-center">
                            Customer Preference Not Available
                        </span>
                        <div class="total-addon row no-gutters align-items-center p-2 mt-4">
                            <div>
                                <img class="b-r-7" :src="item.image">
                            </div>
                            <div class="col pl-2 pr-2">
                                <div class="d-flex justify-content-between w-100">
                                    <div class="d-flex">
                                        <el-tooltip  v-if="item.product_type == 'Non-Veg'" content="Non-Veg" placement="top">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg">
                                        </el-tooltip>
                                        <el-tooltip  v-else-if="item.product_type == 'Veg'" content="Veg" placement="top">
                                            <img  src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg">
                                        </el-tooltip>    
                                        <el-tooltip  v-else-if="item.product_type == 'Egg'" content="Egg" placement="top">
                                            <img  src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg">
                                        </el-tooltip>
                                        <el-tooltip  v-else-if="item.product_type == 'Pescatarian'" content="Pescatarian" placement="top">
                                            <img style="width:16px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg">
                                        </el-tooltip>
                                        <span class="text-white font-weight-bold" :class="{'ml-1':item.product_type != ''}">{{item.product_name}}</span>
                                    </div>    
                                    <span class="text-white font-weight-bold text-uppercase">Total Price: {{productPrice}}</span>
                                </div>
                                <hr style="border:0.7px solid rgb(132, 136, 140);">
                                <div class="d-flex justify-content-between">
                                    <span class="text-white font-weight-bold d-flex align-items-center">{{selected_addon_ids.length}} Add-On</span>
                                    <div class="text-white font-weight-bold d-flex align-items-center">
                                        <span>Qty:</span>
                                        <span>{{item.quantity}}</span>
                                    </div>
                                    <div class="save-addon d-flex align-items-center">
                                        <el-tooltip class="item" effect="dark" content="Item Summary" placement="top">
                                            <span @click="saveItemAddon()" class="pointer">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/saved.svg">
                                            </span>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                            <span class="ml-3 pointer" @click="deleteAddons()">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/trash_addon.svg">
                                            </span>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center pt-0">
                    <button @click="saveAddons()" class="pointer w-100 d-flex justify-content-between align-items-center bg-blue-image p-3 bg-orange-image mt-3 b-r-7">
                        <span class="font-weight-bold fs-16">Save</span>
                        <span>
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/save-arrow.svg">
                        </span>
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import Avatar from 'vue-avatar'
export default {
    props:['modal_name','item','notes','storeorderId'],
    data(){
        return{
            showAddons: true,
            showSectionAddons: 0,
            showNotes: true,
            showPreferences: true,
            showSectionAddons: [],
            addons:[],
            variants:[],
            variant_id: "",
            selected_addons:[],
            preferences:[],
            selected_addon_ids:[],
            selected_id:null,
            addon_quantity:1,
            note:this.notes.value,
            items_qty:1,
            store_addons: [],
            existing_addons:[],
            update_addons: false
        }
    },
    components:{
        Avatar
    },
    methods:{
        saveItemAddon(){
            this.$validator.validate().then(result => {
                if (result == true) {
                    this.store_addons = this.selected_addons
                    let params = {
                        "item_id": this.item._id,
                        "quantity": this.item.quantity,
                        "variant_id": this.item.variant_id,
                        "addons":this.store_addons,
                        "is_customizable":true,
                        "note":this.note
                    }
                    return new Promise(async (resolve, reject) => {
                        let response = await this.$http.post("/mobile/orders/items/edit", params)
                        if (response.data.status_id == 1) {
                            resolve(response.data)
                            this.$emit('hideEditAddons');
                            this.$emit('item_summary');
                            this.$modal.hide(this.modal_name);
                        } else {
                            reject(response.data.reason)
                        }
                    })
                }
                else{
                    this.$toasted.global.error('Item Quantity must be greater than 0');
                }
            })
        },
        expandNotes(){
            if(!this.showNotes){
                this.showNotes = true;
            }
            else{
                this.showNotes = false;
            }
        },
        expandPreferences(){
            if(!this.showPreferences){
                this.showPreferences = true;
            }
            else{
                this.showPreferences = false;
            }
        },
        collapseAddons(section){
            if(this.showSectionAddons.find(x => x === section.addon_section_name)){
                this.showSectionAddons = this.showSectionAddons.filter(function(x) {
                    if (x !== section.addon_section_name) {
                        return x;
                    }
                });
            }
            else{
                this.showSectionAddons.push(section.addon_section_name)
            }    
        },
        async saveAddons(){
            this.$validator.validate().then(result => {
                if (result == true) {
                    this.store_addons = this.selected_addons
                    let params = {
                        "item_id": this.item._id,
                        "quantity": this.item.quantity,
                        "variant_id": this.item.variant_id,
                        "addons":this.store_addons,
                        "is_customizable":true,
                        "note":this.note
                    }
                    return new Promise(async (resolve, reject) => {
                        let response = await this.$http.post("/mobile/orders/items/edit", params)
                        if (response.data.status_id == 1) {
                            resolve(response.data)
                            this.$emit('hideEditAddons');
                            this.$emit('update_addon');
                            this.$emit('update_note',this.note,this.item)
                            this.$modal.hide(this.modal_name);
                        } else {
                            reject(response.data.reason)
                        }
                    })
                }
                else{
                    this.$toasted.global.error('Item Quantity must be greater than 0');
                }
            })        
        },
        hideSelectAddons(){
            this.$emit('hideEditAddons');
            this.$modal.hide(this.modal_name);
        },
        expandAddons(){
            if(!this.showAddons){
                this.showAddons = true;
            }
            else{
                this.showAddons = false;
            }
        },
        selectedVariant(variantIndex){
            this.variant_id = variantIndex
        },
        selectAddon(selected_addon,index){
            let existIndex = null
            if(selected_addon.quantity <= 0){
                // this.$toasted.global.error('Quantity is Unavailable');
            }
            else{
                let addon_Index = null
                if(this.selected_addons.find(x => x._id !== selected_addon._id)){
                    // selected_addon.selected_quantity = 0
                    selected_addon.selected_quantity = 1
                }
                if(this.selected_addons.find(x => x._id === selected_addon._id)){
                    if(this.update_addons == true){
                        this.update_addons = false
                    }
                    else{
                        this.update_addons = true
                    }
                    if(this.existing_addons.find(x => x._id === selected_addon._id)){
                        this.selected_addons.forEach((addon,addonIndex) => {
                            if(addon._id == selected_addon._id){
                                this.selected_addons[addonIndex].quantity = 0
                                selected_addon.selected_quantity = 0
                                this.selected_addons[addonIndex].price = 0
                                addon_Index = addonIndex

                            }
                        })
                    }
                    else{
                        this.selected_addons = this.selected_addons.filter(function(x,index) {
                            if (x._id !== selected_addon._id) {
                                return x;
                            }
                        });
                    }
                    this.selected_addon_ids = this.selected_addon_ids.filter(function(x,index) {
                        if (x !== selected_addon._id) {
                            return x;
                        }
                    });
                    if(this.update_addons == false){
                        if(this.existing_addons.find(x => x._id === selected_addon._id)){
                            selected_addon.selected_quantity = 1
                            this.selected_addons[addon_Index].quantity = 1
                            this.selected_addons[addon_Index].price = selected_addon.price
                            this.selected_addon_ids.push(selected_addon._id)
                        }    
                    }
                }
                else{
                    selected_addon.selected_quantity = 1
                    this.selected_addon_ids.push(selected_addon._id)
                    this.selected_addons.push({'_id':selected_addon._id,'quantity':selected_addon.selected_quantity,'price':selected_addon.price});
                    this.store_addons.push({'_id':selected_addon._id,'quantity':selected_addon.selected_quantity,'price':selected_addon.price})
                    
                }
            }
        },
        increaseQuantity(selected_addon,index){
            let addon_price = selected_addon.price
            addon_price = selected_addon.selected_quantity * selected_addon.price
            if(selected_addon.quantity > 0){
                selected_addon.selected_quantity++
                selected_addon.quantity--
                addon_price = selected_addon.selected_quantity * selected_addon.price
                this.store_addons = this.selected_addons
            }
            else if(selected_addon.quantity == 0){
                this.$toasted.global.error('Quantity is not Available to select quantity');
            }
            this.selected_addons.forEach((e, i) => {
                if (e._id == selected_addon._id) {
                    this.selected_addons[i].quantity++
                    this.selected_addons[i].price = addon_price
                    this.store_addons = this.selected_addons
                }
            });
        },
        decreaseQuantity(selected_addon,index){
            let addon_price = selected_addon.price
            addon_price = selected_addon.selected_quantity * selected_addon.price
            if(selected_addon.quantity >= 0 && selected_addon.selected_quantity>1){
                selected_addon.selected_quantity--
                selected_addon.quantity++
                addon_price = selected_addon.selected_quantity * selected_addon.price
                this.store_addons = this.selected_addons
            }
            if(selected_addon.selected_quantity <= 0 && selected_addon.quantity>0){
               selected_addon.selected_quantity = 0
            }
            if(selected_addon.selected_quantity <= 0){
                selected_addon.selected_quantity = 0
            }
            this.selected_addons.forEach((e, i) => {
                if (e._id == selected_addon._id) {
                    this.selected_addons[i].quantity--
                    this.selected_addons[i].price = addon_price
                    this.store_addons = this.selected_addons
                    if(this.selected_addons[i].quantity == 0){
                        if(this.existing_addons.find(x => x._id === selected_addon._id)){
                            selected_addon.selected_quantity = 0
                            this.selected_addons.forEach((addon,addonIndex) => {
                                if(addon._id == selected_addon._id){
                                    this.selected_addons[addonIndex].quantity = 0
                                    selected_addon.selected_quantity = 0
                                    this.selected_addons[addonIndex].price = 0

                                }
                            })
                        }
                        else{
                            this.selected_addons = this.selected_addons.filter(function(x) {
                                if (x._id !== selected_addon._id) {
                                    return x;
                                }
                            });
                        }
                        this.selected_addon_ids = this.selected_addon_ids.filter(function(x,index) {
                            if (x !== selected_addon._id) {
                                return x;
                            }
                        });
                        if(selected_addon.selected_quantity == 0){
                            if(this.existing_addons.find(x => x._id === selected_addon._id)){
                                selected_addon.selected_quantity = 1
                                this.selected_addons[addon_Index].quantity = 1
                                this.selected_addons[addon_Index].price = selected_addon.price
                                this.selected_addon_ids.push(selected_addon._id)
                            }    
                        }
                    }
                }
            });
        },
        async getAddons(){
            console.log(this.item)
            let params = {
                "product_id": this.item.product_id,
                "item_id": this.item._id,
                "order_id":this.storeorderId,
                "variant_id":this.item.variant_id
            }
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post("/add_ons/addon/get_addons_for_category", params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async loadAddons(params){
            let response  = await this.getAddons(params);
            console.log(response)
            this.addons = response.addons
            this.preferences = response.preferences
        },
        addonQty(addon,addon_index){
            let addon_price = addon.price
            if(addon.selected_quantity == '' ){
                addon.selected_quantity = 0
            }
            if(addon.selected_quantity > addon.quantity){
                addon.selected_quantity = 0 
                this.$toasted.global.error('Selected Quantity must be less than or equal to'+' '+addon.quantity);
            }
            if(addon.quantity == 0 && addon.selected_quantity <= 0){
                addon.selected_quantity = 0
            }
            if(addon.selected_quantity == 0){
                this.selected_addons.forEach((e, i) => {
                    if(e._id == addon._id){
                        this.selected_addons[i].quantity == addon.selected_quantity
                        addon_price = addon.price * addon.selected_quantity
                        this.selected_addons[i].price = addon_price
                    }
                });
            }
            if(addon.quantity>0){
                this.selected_addons.forEach((e, i) => {
                    if (e._id == addon._id) {
                        this.selected_addons[i].quantity = addon.selected_quantity
                        addon.quantity = addon.quantity - this.selected_addons[i].quantity
                        addon_price = addon.price * addon.selected_quantity
                        this.selected_addons[i].price = addon_price
                    }    
                });
            }
            this.selected_addons.forEach((e, i) => {
                if (e._id == addon._id) {
                    addon_price = addon.price * addon.selected_quantity
                    this.selected_addons[i].price = addon_price
                }    
            });
        },
        checkOty(addon,addon_index){
            
        },    
        deleteAddons(){
            this.selected_addons.forEach(addon => {
                addon.quantity = 0
                addon.price = 0
            })
            this.selected_addon_ids = []
            this.store_addons = this.selected_addons
        }    
    },
    computed: {
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
        addon_names(){
            let addon_list = []
            this.addons.forEach(addon => {
                addon_list.push(...addon.addons.map(x =>{return {_id:x._id, quantity:x.quantity, selected_quantity:x.selected_quantity}}))
            });
            return addon_list 
        },
        productPrice(){
                var string = this.item.product_price.replace(',','')
                var res = parseFloat(string.slice(1,string.length))
                return Number(res * this.item.quantity + this.addonPrice)
        },
        addonPrice(){
            return this.selected_addons.reduce((total, addon) => total += parseFloat(addon.price), 0)
        }
    },
    created(){
        
    },
    mounted(){
        this.loadAddons();
        setTimeout(() => {
            this.addons.forEach((addon,index) => {
                addon.addons.forEach(addon_list => {
                    if(addon_list.selected_quantity > 0){
                        this.selected_addon_ids.push(addon_list._id)
                        this.selected_addons.push({'_id':addon_list._id,'quantity':addon_list.selected_quantity,'price':addon_list.price})
                        this.existing_addons.push({'_id':addon_list._id,'quantity':addon_list.selected_quantity,'price':addon_list.price})
                    }
                })
            })
            this.addons.forEach((section) => {
                this.showSectionAddons.push(section.addon_section_name)
            })
        }, 1000);
    },
    beforeMount() {
        this.addons = JSON.parse(JSON.stringify(this.addons));
    },
    beforeDestroy(){ this.$validator.pause()}
    
}
</script>                        
<style scoped>
.unavailable-addons {
  opacity: 0.6 !important;
  pointer-events: none;  
  position: relative;
  vertical-align: middle;
  top: 28%;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
  font-weight: 600;
  height: 26px;
  line-height: 26px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.88);
  text-align: center;
}
.whiteSpace{
    white-space: nowrap;
}
.bg-orange-image:focus{
    outline: 0px auto -webkit-focus-ring-color!important;
    color: #303031!important;
}
.addon-col{
    padding-left: 8px;
    margin-top: 2px;
    padding-right: 8px;
}
.avatar-addons {
    font-size: 50px !important;
    line-height: 50px;
    background-color: transparent!important;
}
.avatar-addons {
    width: 50px;
    min-width: 50px;
    height: 50px;
    line-height: 50px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-weight: 500;
    text-align: center;
    color: #ffffff !important;
    background-color: transparent!important;
}
.word-break{
    word-break: break-word;
}
.variant-addon{
    cursor: pointer;
    width: 120px;
    height: 88px;
    text-align: center;
    border-radius: 7px;
    padding-top: 17px;
    box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
}
.selected_variant{
    background-color: #00448b;
    color: #fff;
}
.addon-border{
  position: relative;
  bottom: 11px;
  border-bottom: 0.1px solid #b2b2b3;
}
.collapse-addon{
    position: relative;
    display: block;
    float: right;
    cursor: pointer;
}
.v-modal-layout{
    overflow: initial !important;
}
.addon-main{
    width: 121px;
    margin-left: 7px;
}
.addon-note{
    height: 96px!important;
    border: 1px solid #b2b2b3!important;
    border-radius: 7px!important;
    padding: 10px 6px!important;
}
.addon-quantity{
    position: relative;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}
.w-52{
    width: 62px !important;
}
.total-addon{
    box-shadow: 0 2px 4px 0 rgba(162, 141, 141, 0.16);
    background-image: linear-gradient(97deg, #005dae 20%, #004fe3 99%);
    border-radius: 7px;
}
.add-custom-addon{
    width: 75px !important;
    border-bottom: none !important;
    color: #fff !important;
}
.bg-green-image{
    background-image: linear-gradient(83deg, #1a9347 8%, #1ca04d 53%, #20b256 93%);
}
.bg-blue-image{
    background-image: linear-gradient(92deg, #004fe3 0%, #005dae 103%);
}
.bg-orange-image{
     background-image: linear-gradient(315deg, #fccb4a -30%, #f0712a 48%);
}
.b-r-7{
    border-radius: 7px;
}
.w-30{
    width: 28px;
}
.rotateIcon{
    transform: rotate(180deg);
}
.iconRotate{
    transform: rotate(0deg) !important;
}
.content-center{
    margin: 0 auto;
    position: relative;
    bottom: 12px;
}
.preferences{
    border-radius: 18px;
    border: solid 1px #b2b2b3;
    padding: 5px 10px 5px 10px;
    display: inline-block;
    margin-left: 4px;
}
</style>